import React from "react"
import { graphql } from "gatsby"
import Loadable from "@loadable/component"
import Breadcrumbs from "~/ui/compositions/navigation/breadcrumb"
import Page from "~/models/page"
import Author from "~/models/author"

import SEO from "~/ui/helpers/seo"
import Frame from "~/ui/layout/frame"
import AboutUsBlock from "~/ui/compositions/generic/about-us"
import CardGridBlock from "~/ui/compositions/generic/card-grid"
import CollapsibleListBlock from "~/ui/compositions/generic/collapsible-list"
import PageCoverBlock from "~/ui/compositions/generic/page-cover"
import ParagraphsBlock from "~/ui/compositions/generic/paragraphs"
import SafetyBlock from "~/ui/compositions/generic/safety"
import TeamBlock from "~/ui/compositions/generic/team"
import Contact from "~/ui/compositions/generic/contact"

import {
  AboutUsPageQuery,
  ContentfulPage,
  ContentfulAuthor,
} from "../../../types/graphql-types"

type Props = {
  data: AboutUsPageQuery
}

function AboutUsPage({ data }: Props) {
  const Analytics = Loadable(() => import("../helpers/analytics"))
  const page = new Page(data.contentfulPage as ContentfulPage)
  const authors = data.allContentfulAuthor.nodes.map(author => {
    return new Author(author as ContentfulAuthor)
  })

  return (
    <Frame>
      <Analytics page={page} />
      <React.Fragment>
        <SEO page={page} />
        {page.blocks.map(block => (
          <React.Fragment key={block.id}>
            {block.name == "PageCover" && 
            <React.Fragment>
              <PageCoverBlock {...block.props} />
              <div className="mb-2 mt-2">
                <div className="relative justify-between md:flex items-center max-w-screen-xl mx-auto py-7 lg:py-0 px-4 md:px-10 xl:px-20">
                  <Breadcrumbs breadcrumbs={page.breadcrumbs} />
                </div>
              </div>
            </React.Fragment>
            }
            {block.name == "AboutUs" && <AboutUsBlock {...block.props} />}
            {block.name == "Paragraphs" && <ParagraphsBlock {...block.props} />}
            {block.name == "CardGrid" && <CardGridBlock {...block.props} />}
            {block.name == "CollapsibleList" && (
              <CollapsibleListBlock {...block.props} />
            )}
            {block.name == "Safety" && <SafetyBlock {...block.props} />}
            {block.name == "Team" && (
              <TeamBlock {...block.props} authors={authors} />
            )}
            {block.name == "Contact" && <Contact {...block.props} />}
          </React.Fragment>
        ))}
      </React.Fragment>
    </Frame>
  )
}

export default AboutUsPage

export const query = graphql`
  query AboutUsPage {
    contentfulPage(url: { pathname: { eq: "/about-us/" } }) {
      ...PageFields
    }
    allContentfulAuthor(sort: { fields: contentful_id }) {
      nodes {
        ...AuthorFields
      }
    }
  }
`
