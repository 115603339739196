import React from "react"

import sanitize from "~/helpers/sanitize"

type Props = {
  title: string
  description?: string
  items: string[]
  background: string
  image?: string
}

Safety.defaultProps = {
  background: "white",
}

function Safety({ title, description }: Props) {
  return (
    <section className="overflow-hidden py-12 md:py-20 bg-gray-100">
      <div className="relative max-w-screen-xl mx-auto px-4 md:px-10 xl:px-20">
        <div className="lg:max-w-[738px] mx-auto relative mb-8 z-10">
          <h2
            className="text-2xl font-bold text-white lg:text-4xl text-center tracking-[-0.025em]"
            dangerouslySetInnerHTML={sanitize(title)}
          ></h2>

          <p
            className="mt-3 text-sm text-white lg:text-xl text-center"
            dangerouslySetInnerHTML={sanitize(description)}
          ></p>
        </div>

        <div className="relative z-10">
          <div className="max-w-[320px] md:max-w-[750px] mx-auto grid grid-cols-3 md:grid-cols-6 gap-y-4 gap-x-4 md:gap-x-8 mt-5">
            <img
              src="/images/general/ecogra.svg"
              alt=""
              width={100}
              className="w-[100px]"
              decoding="async"
            />
            <img
              src="/images/general/truste.svg"
              alt=""
              width={100}
              className="w-[100px]"
              decoding="async"
            />
            <img
              src="/images/general/verisign.svg"
              alt=""
              width={100}
              className="w-[100px]"
              decoding="async"
            />
            <img
              src="/images/general/norton.svg"
              alt=""
              width={100}
              className="w-[100px]"
              decoding="async"
            />
            <img
              src="/images/general/tst.svg"
              alt=""
              width={100}
              className="w-[100px]"
              decoding="async"
            />
            <img
              src="/images/general/ssl.svg"
              alt=""
              width={100}
              className="w-[100px]"
              decoding="async"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Safety
